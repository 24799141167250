import React, { useEffect } from 'react'
import './ModalResizeStyle.scss'

import background from 'assets/images/general/mobile-background.jpg'
import { ReactComponent as ModalIcon } from 'assets/vectors/general/modal-resize-icon.svg'

const ModalResize = () => {
  useEffect(() => {
    const modal = document.querySelectorAll('.modal-resize')
    const initial_width = window.innerWidth
    if (modal.length === 0) return

    if (initial_width <= 1100) {
      modal[0].classList.add('active')
    }

    window.addEventListener('resize', function () {
      const width = window.innerWidth

      if (width <= 1100) {
        modal[0].classList.add('active')
      } else {
        modal[0].classList.remove('active')
      }
    })
  }, [])

  return (
    <div className="modal-resize">
      <div className="background">
        <img src={background} alt="Imagem - Alpa" />
      </div>

      <div className="wrapper">
        <div className="wrapper-icon">
          <ModalIcon />
        </div>

        <div className="wrapper-description">
          <p>
            For a better experience,
            <br />
            access this link trough a<br />
            desktop computer.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ModalResize
