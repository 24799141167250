import React, { useEffect, useState } from 'react'
import './ModalWavesTabsStyle.scss'

import { ReactComponent as ArrowDown } from 'assets/vectors/general/button-arrow-down.svg'
import { ReactComponent as ArrowLeft } from 'assets/vectors/general/button-arrow-left.svg'
import { ReactComponent as ArrowRight } from 'assets/vectors/general/waves-arow-right.svg'

const ModalWavesTabs = () => {
  const [tab, setTab] = useState()
  const [fields, setFields] = useState()
  const [loaded, setLoaded] = useState()
  const years = ['2022', '2023', '2024']
  const yearsLines = new Array(18).fill(0)
  const yearsTitle = new Array(10).fill(0)

  useEffect(() => {
    const page = document.querySelector('main.page')

    page.addEventListener('APILoaded', function () {
      setFields(window.fields_options)
      setLoaded(true)
    })
  }, [])

  const closeModal = () => {
    const modal = document.querySelector('.modal-waves-tabs')

    modal.classList.remove('animate')
  }

  const setupTab = (index, year) => {
    const modal = document.querySelector('.modal-waves-tabs')
    const close = modal.querySelector('.close')
    const background = modal.querySelector('.background')
    const navigation = modal.querySelector('.navigation')
    const yearElement = modal.querySelector(`.year-${year}`)

    close.classList.add('inactive')
    background.classList.add('inactive')
    navigation.classList.add('inactive')

    setTimeout(() => yearElement.classList.add('active'), 300)
    setTimeout(() => setTab(index), 2000)
    setTimeout(() => close.classList.remove('inactive'), 2800)
  }

  useEffect(() => {
    if (tab === 0) {
      const modal = document.querySelector('.modal-waves-tabs')
      const close = modal.querySelector('.close')
      const background = modal.querySelector('.background')
      const navigation = modal.querySelector('.navigation')
      const yearElement1 = modal.querySelector(`.year-2022`)
      const yearElement2 = modal.querySelector(`.year-2023`)
      const yearElement3 = modal.querySelector(`.year-2024`)

      close.classList.add('inactive')

      yearElement1.classList.remove('active')
      yearElement2.classList.remove('active')
      yearElement3.classList.remove('active')

      setTimeout(function () {
        background.classList.remove('inactive')
      }, 800)

      setTimeout(function () {
        navigation.classList.remove('inactive')
        close.classList.remove('inactive')
      }, 1500)

      setTimeout(function () {
        background.classList.remove('inactive')
        navigation.classList.remove('inactive')
        close.classList.remove('inactive')
      }, 2500)
    }
  }, [tab])

  return (
    <div className="modal-waves-tabs">
      <div className="close">
        <button
          type="button"
          title="Return"
          aria-label="Return"
          onClick={() => (tab === 0 || !tab ? closeModal() : setTab(0))}
        >
          <ArrowLeft />
          <span>Return</span>
        </button>
      </div>

      <div className="background">
        <div className="background-image"></div>
        <div className="background-image"></div>
        <div className="background-image"></div>
      </div>

      {years.map((year, yearIndex) => (
        <div className={`year-${year}`} key={`animation-year-${yearIndex}`}>
          {yearsLines.map((item1, item1Index) => (
            <div
              className="line"
              key={`animation-year-${yearIndex}-line-${item1Index}`}
            >
              {yearsTitle.map((item2, item2Index) => (
                <div
                  className="line-year"
                  key={`animation-year-${yearIndex}-line-${item1Index}-item-${item2Index}`}
                >
                  {year}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}

      <div className="navigation">
        <div className="navigation-title">
          <div className="navigation-title--line">
            <span>Waves</span>
            <span>of</span>
          </div>

          <div className="navigation-title--line">
            <span>change</span>
          </div>
        </div>

        <div className="navigation-list">
          <div className="item">
            <button
              type="button"
              className="item-button"
              title="Selecionar ano"
              aria-label="Selecionar ano"
              onClick={() => setupTab(1, 2022)}
            >
              Selecionar ano
            </button>

            <div className="item-title">
              <span>
                Rooting
                <br />
                down
              </span>

              <span>
                Rooting
                <br />
                down
              </span>
            </div>

            <div className="item-number">
              <span>2022</span>
              <span>2022</span>
            </div>

            <div className="item-arrow">
              <ArrowRight />
            </div>
          </div>

          <div className="item">
            <button
              type="button"
              className="item-button"
              title="Selecionar ano"
              aria-label="Selecionar ano"
              onClick={() => setupTab(2, 2023)}
            >
              Selecionar ano
            </button>

            <div className="item-title">
              <span>
                Speeding
                <br />
                up
              </span>

              <span>
                Speeding
                <br />
                up
              </span>
            </div>

            <div className="item-number">
              <span>2023</span>
              <span>2023</span>
            </div>

            <div className="item-arrow">
              <ArrowRight />
            </div>
          </div>

          <div className="item">
            <button
              type="button"
              className="item-button"
              title="Selecionar ano"
              aria-label="Selecionar ano"
              onClick={() => setupTab(3, 2024)}
            >
              Selecionar ano
            </button>

            <div className="item-title">
              <span>
                Flying
                <br />
                high
              </span>

              <span>
                Flying
                <br />
                high
              </span>
            </div>

            <div className="item-number">
              <span>2024</span>
              <span>2024</span>
            </div>

            <div className="item-arrow">
              <ArrowRight />
            </div>
          </div>
        </div>
      </div>

      {loaded && (
        <div className="tabs">
          {years.map((year, number) => (
            <div
              className={tab === number + 1 ? 'tab active' : 'tab'}
              key={`modal-waves-tabs-tab-${number}`}
            >
              <div className="tab-title">
                <div className="tab-title--line">
                  <span>{year}</span>
                </div>

                <div className="tab-title--line">
                  <span>Waves</span>
                  <span>of</span>
                  <span>change</span>
                </div>
              </div>

              <div className="tab-flex">
                <div className="tab-left">
                  <div
                    className="tab-left--title"
                    dangerouslySetInnerHTML={{
                      __html: fields[`waves-tabs-${year}-title`],
                    }}
                  ></div>

                  <div className="tab-left--description">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: fields[`waves-tabs-${year}-description`],
                      }}
                    ></p>
                  </div>

                  <div className="tab-left--group">
                    <div className="tab-left--group_title">
                      {fields['waves-tabs-title-left']}
                    </div>

                    <div className="tab-left--group_items">
                      {fields[`waves-tabs-${year}-items`].map((item, index) => (
                        <div
                          className="item"
                          key={`modal-waves-tabs-tab-${number}-item-${index}`}
                        >
                          <div
                            className="item-bar"
                            style={{ backgroundColor: `${item.color}` }}
                          ></div>

                          <div
                            className="item-title"
                            style={{ color: `${item.color}` }}
                            dangerouslySetInnerHTML={{
                              __html: item.title,
                            }}
                          ></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="tab-right">
                  <div className="tab-right--title">
                    {fields['waves-tabs-title-right']}
                  </div>

                  <div
                    className="tab-right--description"
                    dangerouslySetInnerHTML={{
                      __html: fields[`waves-tabs-${year}-content`],
                    }}
                  ></div>

                  <div className="tab-right--cta">
                    <a
                      href={fields['actions-action-2-link']}
                      title={fields['actions-action-2-text']}
                      aria-label={fields['actions-action-2-text']}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      <span>{fields['actions-action-2-text']}</span>
                      <ArrowDown />
                    </a>
                  </div>
                </div>
              </div>

              <div className="tab-bar">
                <div className="tab-bar--1"></div>
                <div className="tab-bar--2"></div>
                <div className="tab-bar--3"></div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ModalWavesTabs
