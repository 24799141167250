import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './SectionLoginStyle.scss'

import ProgressScreen from 'components/general/ProgressScreen/'

import background from 'assets/images/general/mobile-background.jpg'
import { ReactComponent as ArrowRight } from 'assets/vectors/general/button-arrow-right.svg'
import { ReactComponent as Circle } from 'assets/vectors/general/button-circle.svg'

const SectionLogin = () => {
  const [message, setMessage] = useState({
    open: false,
    text: '',
  })

  useEffect(() => {
    const page = document.querySelector('#page-login')
    const section = page.querySelector('.section-login')

    section.classList.add('animate')

    document.cookie =
      'dt_alpa_logged=false; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'
  }, [])

  const formLogin = () => {
    const section = document.querySelector('#page-login .section-login')
    const form = section.querySelector('.wrapper-form')
    const groups = form.querySelectorAll('.field-group')
    const email = groups[0].querySelector('.field').value
    const password = groups[1].querySelector('.field').value
    const credentials = {
      email: email,
      password: password,
    }

    setMessage({
      open: true,
      text: 'Validating credentials...',
    })

    if (!email || !password) {
      setTimeout(function () {
        setMessage({
          open: true,
          text: 'Fill in the email and password.',
        })

        setTimeout(function () {
          setMessage({
            open: false,
            text: 'Fill in the email and password.',
          })
        }, 2000)
      }, 1000)
      return
    }

    axios
      .post(
        `https://gzowht7b6g.execute-api.us-east-1.amazonaws.com/default/suvinil-td-authentication`,
        credentials,
      )
      .then((res) => {
        let expiryDate = new Date()
        expiryDate.setMonth(expiryDate.getMonth() + 3)

        document.cookie = `dt_alpa_logged=true; expires=${expiryDate}; Path=/;`

        setTimeout(function () {
          setMessage({
            open: true,
            text: 'Successfully logged in, redirecting...',
          })

          setTimeout(function () {
            setMessage({
              open: false,
              text: 'Successfully logged in, redirecting...',
            })

            setTimeout(function () {
              window.location.href = `${window.location.href}home/`
            }, 800)
          }, 2000)
        }, 1000)
      })
      .catch((error) => {
        document.cookie =
          'dt_alpa_logged=false; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'

        setTimeout(function () {
          setMessage({
            open: true,
            text: 'Invalid email or password.',
          })

          setTimeout(function () {
            setMessage({
              open: false,
              text: 'Invalid email or password.',
            })
          }, 2000)
        }, 1000)
      })
  }

  return (
    <section className="section section-login">
      <ProgressScreen message={message} />

      <div className="background">
        <img src={background} alt="Imagem - Alpa" />
      </div>

      <div className="wrapper">
        <div className="wrapper-title">Login</div>

        <form className="wrapper-form">
          <div className="field-group" data-name="email">
            <div className="label">E-mail</div>
            <input
              type="email"
              className="field"
              placeholder="Type your e-mail"
            />
          </div>

          <div className="field-group" data-name="password">
            <div className="label">Password</div>
            <input
              type="password"
              className="field"
              placeholder="Type your password"
            />
          </div>
        </form>

        <div className="wrapper-cta">
          <button
            type="button"
            className="wrapper-cta--button"
            title="Unlock the future"
            aria-label="Unlock the future"
            onClick={() => formLogin()}
          >
            Unlock the future
          </button>

          <div className="wrapper-cta--content">
            <div className="wrapper-cta--content_title">Unlock the future</div>
          </div>

          <div className="wrapper-cta--circle">
            <div className="wrapper-cta--circle_element">
              <Circle />
            </div>

            <div className="wrapper-cta--circle_arrow">
              <ArrowRight />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionLogin
