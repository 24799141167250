import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Redirect } from 'react-router-dom'
import './HomeStyle.scss'

import API from 'utils/api'

import Preloader from 'components/general/Preloader'
import ModalDescription from 'components/general/ModalDescription'
import ModalWavesDescription from 'components/general/ModalWavesDescription'
import ModalWavesTabs from 'components/general/ModalWavesTabs'

import Tabs from 'components/home/Tabs'
import Navigation from 'components/home/Navigation'
import Actions from 'components/home/Actions'

const Home = () => {
  API(7)

  const cookie = document.cookie.includes('dt_alpa_logged=true')
  const [loaded, setLoaded] = useState()

  useEffect(() => {
    const page = document.querySelector('main.page')
    const body = document.querySelector('body')

    body.classList.remove('page-single')
    body.classList.add('page-home')
    body.classList.add('not-scrollable')

    page.addEventListener('APILoaded', function () {
      setLoaded(true)
    })
  }, [])

  return (
    <>
      {cookie ? '' : <Redirect to="/" />}

      {!isMobile && (
        <>
          <Preloader />
          <ModalDescription />
          <ModalWavesDescription />
          <ModalWavesTabs />

          <main id="page-home" className="page">
            {loaded && (
              <>
                <Tabs />
                <Navigation />
                <Actions />
              </>
            )}
          </main>
        </>
      )}
    </>
  )
}

export default Home
