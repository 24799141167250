import { useEffect } from 'react'
import './ProgressScreenStyle.scss'

const ProgressScreen = ({ message }) => {
  useEffect(() => {
    const body = document.querySelector('body')
    const content = body.querySelectorAll('.screen-progress')

    if (message.open) {
      if (content.length === 1) return

      const screenProgress = document.createElement('div')
      screenProgress.classList.add('screen-progress')

      const screenMessage = document.createElement('div')
      screenMessage.classList.add('screen-message')

      const screenSpinner = document.createElement('div')
      screenSpinner.classList.add('spinner')

      const screenSpan1 = document.createElement('span')
      const screenSpan2 = document.createElement('span')

      screenSpinner.appendChild(screenSpan1)
      screenSpinner.appendChild(screenSpan2)
      screenProgress.appendChild(screenMessage)
      screenProgress.appendChild(screenSpinner)

      body.appendChild(screenProgress)
    } else {
      if (content.length === 0) return

      setTimeout(() => content[0].remove(), 700)
    }
  }, [message])

  useEffect(() => {
    setTimeout(function () {
      const body = document.querySelector('body')
      const content = body.querySelectorAll('.screen-progress')
      if (content.length === 0) return
      const contentMessage = content[0].querySelector('.screen-message')

      if (message.open) {
        contentMessage.innerHTML = message.text

        content[0].classList.add('open')
        body.classList.add('shadow')
        body.classList.add('hidden')
      } else {
        contentMessage.innerHTML = message.text

        content[0].classList.remove('open')
        body.classList.remove('shadow')
        body.classList.remove('hidden')
      }
    }, 100)
  }, [message])

  return true
}

export default ProgressScreen
