import React from 'react'
import './ActionsStyle.scss'

import { ReactComponent as ArrowDown } from 'assets/vectors/general/button-arrow-down.svg'

const Actions = () => {
  const options = window.fields_options

  const openModal = () => {
    const modal = document.querySelector('.modal-waves-description')

    modal.classList.add('animate')
  }

  return (
    <div className="actions">
      <div className="action">
        <button
          type="button"
          title={options['actions-action-1-text']}
          aria-label={options['actions-action-1-text']}
          onClick={() => openModal()}
        >
          <span>{options['actions-action-1-text']}</span>
        </button>
      </div>

      {options['actions-action-2-link'] && (
        <div className="action">
          <a
            href={options['actions-action-2-link']}
            title={options['actions-action-2-text']}
            aria-label={options['actions-action-2-text']}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <span>{options['actions-action-2-text']}</span>
            <ArrowDown />
          </a>
        </div>
      )}
    </div>
  )
}

export default Actions
