import React, { useEffect, useState } from 'react'
import './ModalWavesDescriptionStyle.scss'

import background1 from 'assets/images/general/modal-waves-image-1.jpg'
import background2 from 'assets/images/general/modal-waves-image-2.jpg'
import { ReactComponent as ArrowRight } from 'assets/vectors/general/button-arrow-right.svg'
import { ReactComponent as ArrowDown } from 'assets/vectors/general/button-arrow-down.svg'
import { ReactComponent as ArrowLeft } from 'assets/vectors/general/button-arrow-left.svg'
import { ReactComponent as Circle } from 'assets/vectors/general/button-circle.svg'

const ModalWavesDescription = () => {
  const [step, setStep] = useState(1)
  const [fields, setFields] = useState()
  const [loaded, setLoaded] = useState()

  useEffect(() => {
    const page = document.querySelector('main.page')

    page.addEventListener('APILoaded', function () {
      setFields(window.fields_options)
      setLoaded(true)
    })
  }, [])

  const closeModal = () => {
    const modal = document.querySelector('.modal-waves-description')

    modal.classList.remove('animate')
  }

  const openModal = () => {
    const modal = document.querySelector('.modal-waves-tabs')

    modal.classList.add('animate')
  }

  return (
    <div className="modal-waves-description">
      {loaded && (
        <>
          <div className="close">
            <button
              type="button"
              title="Return"
              aria-label="Return"
              onClick={() => (step === 1 ? closeModal() : setStep(1))}
            >
              <ArrowLeft />
              <span>Return</span>
            </button>
          </div>

          <div className={step === 1 ? 'step step-1 active' : 'step step-1'}>
            <div className="background">
              <img src={background1} alt="Imagem - Alpa" />
            </div>

            <div className="wrapper">
              <div className="wrapper-title">
                <div className="wrapper-title--line">
                  <span>Waves</span>
                  <span>of</span>
                </div>

                <div className="wrapper-title--line">
                  <span>change</span>
                </div>
              </div>

              <div className="wrapper-cta">
                <button
                  type="button"
                  className="wrapper-cta--button"
                  title={fields['waves-description-text-button']}
                  aria-label={fields['waves-description-text-button']}
                  onClick={() => setStep(2)}
                >
                  {fields['waves-description-text-button']}
                </button>

                <div className="wrapper-cta--content">
                  <div className="wrapper-cta--content--title">
                    {fields['waves-description-text-button']}
                  </div>
                </div>

                <div className="wrapper-cta--circle">
                  <div className="wrapper-cta--circle--element">
                    <Circle />
                  </div>

                  <div className="wrapper-cta--circle--arrow">
                    <ArrowRight />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={step === 2 ? 'step step-2 active' : 'step step-2'}>
            <div className="background">
              <img src={background2} alt="Imagem - Alpa" />
            </div>

            <div className="wrapper">
              <div className="wrapper-columns">
                <div className="column">
                  <div
                    className="column-description"
                    dangerouslySetInnerHTML={{
                      __html: fields['waves-description-content-1'],
                    }}
                  ></div>
                </div>

                <div className="column">
                  <div
                    className="column-description"
                    dangerouslySetInnerHTML={{
                      __html: fields['waves-description-content-2'],
                    }}
                  ></div>
                </div>
              </div>

              <div className="wrapper-actions">
                <div className="wrapper-download">
                  <a
                    href={fields['actions-action-2-link']}
                    title={fields['actions-action-2-text']}
                    aria-label={fields['actions-action-2-text']}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <span>{fields['actions-action-2-text']}</span>
                    <ArrowDown />
                  </a>
                </div>

                <div className="wrapper-cta">
                  <button
                    type="button"
                    className="wrapper-cta--button"
                    title={fields['waves-description-2-text-button']}
                    aria-label={fields['waves-description-2-text-button']}
                    onClick={() => openModal()}
                  >
                    {fields['waves-description-2-text-button']}
                  </button>

                  <div className="wrapper-cta--content">
                    <div className="wrapper-cta--content--title">
                      {fields['waves-description-2-text-button']}
                    </div>
                  </div>

                  <div className="wrapper-cta--circle">
                    <div className="wrapper-cta--circle--element">
                      <Circle />
                    </div>

                    <div className="wrapper-cta--circle--arrow">
                      <ArrowRight />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ModalWavesDescription
