import React, { useEffect, useState } from 'react'
import './ModalDescriptionStyle.scss'

import { ReactComponent as ArrowRight } from 'assets/vectors/general/button-arrow-right.svg'
import { ReactComponent as ArrowDown } from 'assets/vectors/general/button-arrow-down.svg'
import { ReactComponent as Circle } from 'assets/vectors/general/button-circle.svg'

const ModalDescription = () => {
  const [fields, setFields] = useState()
  const [loaded, setLoaded] = useState()
  const [modalRemoved, setModalRemoved] = useState()

  useEffect(() => {
    if (!modalRemoved) return

    const page = document.querySelector('main.page')
    const header = document.querySelector('#main-header')

    header.setAttribute('theme', 'single')

    setTimeout(() => page.classList.add('animate'), 300)

    if (page.id === 'page-home') {
      setTimeout(function () {
        window.tab_index = 1
        setTimeout(() => page.dispatchEvent(new Event('changedTab')), 10)
      }, 1000)
    }
  }, [modalRemoved])

  useEffect(() => {
    const page = document.querySelector('main.page')

    page.addEventListener('APILoaded', function () {
      setFields(window.fields_options)
      setLoaded(true)
    })
  }, [])

  return (
    <div
      className={
        modalRemoved ? 'modal-description remove' : 'modal-description'
      }
    >
      {loaded && (
        <>
          <div className="wrapper">
            <div className="wrapper-column">
              <div
                className="wrapper-column--description"
                dangerouslySetInnerHTML={{
                  __html: fields['modal-description-content-1'],
                }}
              ></div>
            </div>

            <div className="wrapper-column">
              <div
                className="wrapper-column--description"
                dangerouslySetInnerHTML={{
                  __html: fields['modal-description-content-2'],
                }}
              ></div>

              {fields['actions-action-2-link'] && (
                <div className="wrapper-column--cta">
                  <a
                    href={fields['actions-action-2-link']}
                    title={fields['actions-action-2-text']}
                    aria-label={fields['actions-action-2-text']}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <span>{fields['actions-action-2-text']}</span>
                    <ArrowDown />
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className="cta">
            <button
              type="button"
              className="cta-button"
              title="Unlock change"
              aria-label="Unlock change"
              onClick={() => setModalRemoved(true)}
            >
              Unlock change
            </button>

            <div className="cta-content">
              <div className="cta-content--title">Unlock change</div>
            </div>

            <div className="cta-circle">
              <div className="cta-circle--element">
                <Circle />
              </div>

              <div className="cta-circle--arrow">
                <ArrowRight />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ModalDescription
