import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import './sass/main.scss'

import Header from 'components/general/Header'
import ModalResize from 'components/general/ModalResize'

import Login from 'container/login'
import Home from 'container/home'
import Single from 'container/single'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <ModalResize />

      <Switch>
        <Route path="/" exact={true} component={Login} />
        <Route path="/home" exact={true} component={Home} />
        <Route path="/home/:page" component={Single} />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)
