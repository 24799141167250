import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './HeaderStyle.scss'

import { ReactComponent as LogoIcon1 } from 'assets/vectors/general/header-logo-icon-1.svg'
import { ReactComponent as LogoText } from 'assets/vectors/general/header-logo-text.svg'

const Header = () => {
  useEffect(() => {
    const header = document.querySelector('#main-header')

    setTimeout(() => header.classList.add('animate'), 500)
  }, [])

  return (
    <header id="main-header" theme="default">
      <div className="wrapper">
        <Link
          to="/home/?preloader=false"
          title="Go to homepage"
          aria-label="Go to homepage"
          className="wrapper-link"
          onClick={() => setTimeout(() => window.location.reload(), 100)}
        >
          Go to homepage
        </Link>

        <div className="wrapper-icon">
          <div className="wrapper-icon--1">
            <LogoIcon1 />
          </div>
        </div>

        <div className="wrapper-text">
          <LogoText />
        </div>
      </div>
    </header>
  )
}

export default Header
