import React, { useEffect, useState } from 'react'
import './PreloaderStyle.scss'

import { split } from 'utils/text'

import { ReactComponent as LogoIcon1 } from 'assets/vectors/general/header-logo-icon-1.svg'
import { ReactComponent as LogoText } from 'assets/vectors/general/header-logo-text.svg'
import { ReactComponent as ArrowRight } from 'assets/vectors/general/button-arrow-right.svg'
import { ReactComponent as Circle } from 'assets/vectors/general/button-circle.svg'

const Preloader = () => {
  const jump = window.location.href.includes('?preloader=false')
  const [modalRemoved, setModalRemoved] = useState()

  useEffect(() => {
    if (!modalRemoved) return
    if (jump) return

    const modal = document.querySelector('.modal-description')

    setTimeout(() => modal.classList.add('animate'), 300)
  }, [jump, modalRemoved])

  useEffect(() => {
    const preloader = document.querySelector('#main-preloader')
    const page = document.querySelector('main.page')
    const header = document.querySelector('#main-header')
    const modal = document.querySelector('.modal-description')

    if (jump) {
      header.setAttribute('theme', 'single')
      modal.classList.add('remove')

      setTimeout(function () {
        setModalRemoved(true)

        let url = window.location.href
        url = url.replace('?preloader=false', '')

        window.history.replaceState(null, null, url)

        setTimeout(() => page.classList.add('animate'), 300)

        if (page.id === 'page-home') {
          setTimeout(function () {
            window.tab_index = 1
            setTimeout(() => page.dispatchEvent(new Event('changedTab')), 10)
          }, 1000)
        }
      }, 2000)
    } else {
      const allTitles = preloader.querySelectorAll('.wrapper-title')

      setTimeout(() => preloader.classList.add('animate'), 800)

      for (let i = 0; i < allTitles.length; i++) {
        const title = allTitles[i]

        split(title)

        let html = title.innerHTML
        html = html.replaceAll('<s>', '<span>')
        html = html.replaceAll('</s>', '</span>')

        title.innerHTML = html
      }
    }
  }, [jump])

  return (
    <div
      id="main-preloader"
      className={
        jump
          ? modalRemoved
            ? 'remove jump'
            : 'jump'
          : modalRemoved && 'remove'
      }
    >
      {jump && (
        <div className="logo">
          <div className="logo-icon">
            <div className="logo-icon--1">
              <LogoIcon1 />
            </div>
          </div>

          <div className="logo-text">
            <LogoText />
          </div>
        </div>
      )}

      {!jump && (
        <>
          <div className="wrapper">
            <div className="wrapper-title">We are already moving.</div>
            <div className="wrapper-title">Now let's organize our</div>
            <div className="wrapper-title">pathways to the future.</div>
          </div>

          <div className="cta">
            <button
              type="button"
              className="cta-button"
              title="Let's experience"
              aria-label="Let's experience"
              onClick={() => setModalRemoved(true)}
            >
              Let's experience
            </button>

            <div className="cta-content">
              <div className="cta-content--title">Let's experience</div>
              <div className="cta-content--title">the future together</div>
            </div>

            <div className="cta-circle">
              <div className="cta-circle--element">
                <Circle />
              </div>

              <div className="cta-circle--arrow">
                <ArrowRight />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Preloader
