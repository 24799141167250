import React from 'react'
import './LoginStyle.scss'

import SectionLogin from 'components/login/SectionLogin'

const Login = () => {
  return (
    <main id="page-login" className="page">
      <SectionLogin />
    </main>
  )
}

export default Login
