import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Redirect } from 'react-router'
import './SingleStyle.scss'

import API from 'utils/api'

import Preloader from 'components/general/Preloader'
import ModalDescription from 'components/general/ModalDescription'
import ModalWavesDescription from 'components/general/ModalWavesDescription'
import ModalWavesTabs from 'components/general/ModalWavesTabs'

import SectionContent from 'components/single/SectionContent'
import Actions from 'components/single/Actions'

const Single = ({ match }) => {
  const cookie = document.cookie.includes('dt_alpa_logged=true')
  const pages = {
    'plug-and-play': 74,
    'knowledge-enhancement': 113,
    'omnichannel-ecosystem': 117,
    'smart-factory': 122,
    'sustainability-boost': 125,
    'meaningful-communities': 128,
    'tech-for-new-products': 131,
    'lifestyle-as-a-service': 134,
  }
  const redirect = !pages[match.params.page]
  const [loaded, setLoaded] = useState()

  API(pages[match.params.page])

  useEffect(() => {
    const page = document.querySelector('main.page')
    const body = document.querySelector('body')

    body.classList.remove('page-home')
    body.classList.add('page-single')
    body.classList.remove('not-scrollable')

    page.addEventListener('APILoaded', function () {
      setLoaded(true)
    })
  }, [])

  return (
    <>
      {cookie ? '' : <Redirect to="/" />}
      {redirect && <Redirect to="/home/?preloader=false" />}

      {!isMobile && (
        <>
          <Preloader />
          <ModalDescription />
          <ModalWavesDescription />
          <ModalWavesTabs />

          <main id="page-single" className="page">
            {loaded && (
              <>
                <Actions />
                <SectionContent />
              </>
            )}
          </main>
        </>
      )}
    </>
  )
}

export default Single
