import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './SectionContentStyle.scss'

import { ReactComponent as ArrowLeft } from 'assets/vectors/general/button-arrow-left.svg'
import { ReactComponent as ArrowDown } from 'assets/vectors/single/accordion-arrow-down.svg'
import { ReactComponent as Tooltip } from 'assets/vectors/single/accordion-tooltip.svg'

const SectionContent = () => {
  const fields = window.fields_page
  const mainYears = ['2022', '2023', '2024']
  const mainQuarter = new Array(4).fill(0)
  const mainQuarterMonths = new Array(3).fill(0)

  const setupAccordion = (e) => {
    const element = e.target
    const accordion = element.closest('.accordion')
    const wrapper = accordion.querySelector('.accordion-wrapper')
    const content = accordion.querySelector('.accordion-content')
    const height = content.offsetHeight

    if (accordion.classList.contains('active')) {
      accordion.classList.remove('active')
      wrapper.style.height = '0px'
    } else {
      accordion.classList.add('active')
      wrapper.style.height = `${height}px`
    }
  }

  useEffect(() => {
    const root = document.documentElement

    root.style.setProperty('--main-color', fields['color-2'])
  }, [fields])

  useEffect(() => {
    const page = document.querySelector('#page-single')
    const accordions = page.querySelectorAll('.accordion')
    const allYears = ['2022', '2023', '2024']

    const setupMonths = (item) => {
      const years = item.querySelectorAll('.year')

      for (let i = 0; i < years.length; i++) {
        const year = years[i]
        const months = year.querySelectorAll('.year-quarter--month')

        for (let x = 0; x < months.length; x++) {
          const month = months[x]

          month.setAttribute('data-month', x + 1)

          if (i === 0) {
            month.setAttribute('data-index', x + 1)
          } else if (i === 1) {
            month.setAttribute('data-index', x + 13)
          } else if (i === 2) {
            month.setAttribute('data-index', x + 25)
          }
        }
      }
    }

    const setupMarker = (item, marker) => {
      const yearInit = marker.getAttribute('data-year-init')
      const yearEnd = marker.getAttribute('data-year-end')
      const monthInit = marker.getAttribute('data-month-init')
      const monthEnd = marker.getAttribute('data-month-end')
      let decreaseValue = item.getBoundingClientRect().left
      decreaseValue = decreaseValue - 46

      let elementInit = item.querySelector(`.year[data-year="${yearInit}"]`)
      let elementEnd = item.querySelector(`.year[data-year="${yearEnd}"]`)

      elementInit = elementInit.querySelector(`[data-month="${monthInit}"]`)
      elementEnd = elementEnd.querySelector(`[data-month="${monthEnd}"]`)

      let position1 = elementInit.getBoundingClientRect()
      position1 = position1.left - 46 - decreaseValue

      let position2 = elementEnd.getBoundingClientRect()
      position2 = position2.left - 46 - decreaseValue

      marker.style.left = `${position1}px`
      marker.style.width = `${position2 - position1 + 25}px`
    }

    const removeMonths = (item, marker) => {
      const months = item.querySelectorAll('.year-quarter--month')
      const yearInit = marker.getAttribute('data-year-init')
      const yearEnd = marker.getAttribute('data-year-end')
      const monthInit = marker.getAttribute('data-month-init')
      const monthEnd = marker.getAttribute('data-month-end')

      let yearInitIndex, yearEndIndex

      for (let i = 0; i < allYears.length; i++) {
        const year = allYears[i]

        if (year === yearInit) yearInitIndex = i + 1
        if (year === yearEnd) yearEndIndex = i + 1
      }

      let newMonthInitIndex, newMonthEndIndex

      if (yearInitIndex === 1) {
        newMonthInitIndex = Number(monthInit)
      } else if (yearInitIndex === 2) {
        newMonthInitIndex = Number(monthInit) + 12
      } else if (yearInitIndex === 3) {
        newMonthInitIndex = Number(monthInit) + 24
      }

      if (yearEndIndex === 1) {
        newMonthEndIndex = Number(monthEnd)
      } else if (yearEndIndex === 2) {
        newMonthEndIndex = Number(monthEnd) + 12
      } else if (yearEndIndex === 3) {
        newMonthEndIndex = Number(monthEnd) + 24
      }

      for (let i = 0; i < months.length; i++) {
        const month = months[i]
        const monthIndex = Number(month.getAttribute('data-index'))

        if (monthIndex >= newMonthInitIndex && monthIndex <= newMonthEndIndex) {
          month.classList.add('remove')
        }
      }
    }

    for (let i = 0; i < accordions.length; i++) {
      const accordion = accordions[i]
      const items = accordion.querySelectorAll('.right .item')

      for (let x = 0; x < items.length; x++) {
        const item = items[x]
        const marker1 = item.querySelector('.marker-1')
        const marker2 = item.querySelector('.marker-2')

        setupMonths(item)
        setupMarker(item, marker1)

        if (marker2) {
          removeMonths(item, marker2)
          setupMarker(item, marker2)
        }
      }
    }
  }, [])

  return (
    <section className="section section-content">
      <div
        className="background"
        style={{
          backgroundImage: `linear-gradient(to bottom right, ${fields['color-1']}, ${fields['color-2']})`,
        }}
      ></div>

      <div className="return container">
        <Link to="/home/?preloader=false" title="Return" aria-label="Return">
          <ArrowLeft />
          <span>Return</span>
        </Link>
      </div>

      <div className="page-title container">{fields.title}</div>

      <div className="accordions container">
        {fields['content'].map((accordion, accordionIndex) => (
          <div className="accordion" key={`accordion-${accordionIndex}`}>
            <div className="accordion-button">
              <button
                type="button"
                title="Ver conteúdo"
                aria-label="Ver conteúdo"
                onClick={(e) => setupAccordion(e)}
              >
                <ArrowDown />
              </button>
            </div>

            <div
              className="accordion-header"
              onClick={(e) => setupAccordion(e)}
            >
              <div
                className="accordion-header--title"
                dangerouslySetInnerHTML={{ __html: accordion.title }}
              ></div>

              <div className="accordion-header--description">
                <p>{accordion['text']}</p>
              </div>
            </div>

            <div className="accordion-wrapper" style={{ height: '0px' }}>
              <div className="accordion-content">
                <div className="accordion-flex">
                  <div className="left">
                    <div className="left-description">
                      <p>{accordion.description}</p>
                    </div>
                  </div>

                  <div className="center">
                    {accordion['items'].map((item, itemIndex) => (
                      <div
                        className="item"
                        key={`accordion-${accordionIndex}-items-1-item-${itemIndex}`}
                      >
                        <div className="item-title">{item.title}</div>

                        <div className="item-icon">
                          <Tooltip />
                        </div>

                        <div className="item-tooltip">
                          <div className="item-tooltip--icon"></div>

                          <div
                            className="item-tooltip--description"
                            dangerouslySetInnerHTML={{ __html: item.tooltip }}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="right">
                    <div className="right-header">
                      <div className="right-header--item">2022</div>
                      <div className="right-header--item">2023</div>
                      <div className="right-header--item">2024</div>
                    </div>

                    <div className="right-quarters">
                      <div className="right-quarters--item">Q1</div>
                      <div className="right-quarters--item">Q2</div>
                      <div className="right-quarters--item">Q3</div>
                      <div className="right-quarters--item">Q4</div>

                      <div className="right-quarters--item">Q1</div>
                      <div className="right-quarters--item">Q2</div>
                      <div className="right-quarters--item">Q3</div>
                      <div className="right-quarters--item">Q4</div>

                      <div className="right-quarters--item">Q1</div>
                      <div className="right-quarters--item">Q2</div>
                      <div className="right-quarters--item">Q3</div>
                      <div className="right-quarters--item">Q4</div>
                    </div>

                    <div className="right-items">
                      {accordion['items'].map((item, itemIndex) => (
                        <div
                          className="item"
                          key={`accordion-${accordionIndex}-items-2-item-${itemIndex}`}
                        >
                          <div
                            className="marker marker-1"
                            data-year-init={item['timeline-1']['year-init']}
                            data-year-end={item['timeline-1']['year-end']}
                            data-month-init={item['timeline-1']['month-init']}
                            data-month-end={item['timeline-1']['month-end']}
                          ></div>

                          {item['timeline-option'] && (
                            <div
                              className="marker marker-2"
                              data-year-init={item['timeline-2']['year-init']}
                              data-year-end={item['timeline-2']['year-end']}
                              data-month-init={item['timeline-2']['month-init']}
                              data-month-end={item['timeline-2']['month-end']}
                            ></div>
                          )}

                          {mainYears.map((year, yearIndex) => (
                            <div
                              className="year"
                              data-year={year}
                              key={`accordion-${accordionIndex}-items-${itemIndex}-year-${yearIndex}`}
                            >
                              {mainQuarter.map((quarter, quarterIndex) => (
                                <div
                                  className="year-quarter"
                                  key={`accordion-${accordionIndex}-items-${itemIndex}-year-${yearIndex}-quarter-${quarterIndex}`}
                                >
                                  {mainQuarterMonths.map(
                                    (month, monthIndex) => (
                                      <div
                                        className="year-quarter--month"
                                        key={`accordion-${accordionIndex}-items-${itemIndex}-year-${yearIndex}-quarter-${quarterIndex}-month-${monthIndex}`}
                                      ></div>
                                    ),
                                  )}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default SectionContent
