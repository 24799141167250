import { useEffect, useState } from 'react'

const API = (pageID) => {
  const [request1, setRequest1] = useState()
  const [request2, setRequest2] = useState()

  useEffect(() => {
    const options = require('api/options.json').acf
    window.fields_options = options
    setRequest1(true)

    const page = require(`api/page-${pageID}.json`).acf
    window.fields_page = page
    setRequest2(true)
  }, [pageID])

  useEffect(() => {
    if (!request1 || !request2) return

    const page = document.querySelector('main.page')

    page.dispatchEvent(new Event('APILoaded'))
  }, [request1, request2])
}

export default API
