import each from 'lodash/each'

const calculate = (spans) => {
  const lines = []
  let words = []

  let position = spans[0].offsetTop

  each(spans, (span, index) => {
    if (span.offsetTop === position) {
      words.push(span)
    }

    if (span.offsetTop !== position) {
      lines.push(words)

      words = []
      words.push(span)

      position = span.offsetTop
    }

    if (index + 1 === spans.length) {
      lines.push(words)
    }
  })

  return lines
}

const splitText = (text, expression) => {
  const splits = text.split('<br>')

  let words = []

  each(splits, (item, index) => {
    if (index > 0) {
      words.push('<br>')
    }

    words = words.concat(item.split(expression))

    let isLink = false
    let link = ''

    const innerHTML = []

    each(words, (word) => {
      if (
        !isLink &&
        (word.includes('<span') ||
          word.includes('<a') ||
          word.includes('<strong') ||
          word.includes('<svg'))
      ) {
        link = ''

        isLink = true
      }

      if (isLink) {
        link += ` ${word}`
      }

      if (
        isLink &&
        (word.includes('/span>') ||
          word.includes('/a>') ||
          word.includes('/strong>') ||
          word.includes('/svg>'))
      ) {
        innerHTML.push(link)

        link = ''
      }

      if (!isLink && link === '') {
        innerHTML.push(word)
      }

      if (
        isLink &&
        (word.includes('/span>') ||
          word.includes('/a>') ||
          word.includes('/strong>') ||
          word.includes('/svg>'))
      ) {
        isLink = false
      }
    })

    words = innerHTML
  })

  return words
}

const parseLine = (line) => {
  if (line !== ' ') {
    line = line.trim()
  }

  if (line === '' || line === ' ') {
    return line
  } else {
    return line === '<br>'
      ? '<br>'
      : `<s>${line}</s>` + (line.length > 1 ? ' ' : '')
  }
}

export const split = (element, expression = ' ', append = true) => {
  const words = splitText(
    element.innerHTML.toString().replace(/\n/g, ' '),
    expression,
  )

  let innerHTML = ''

  each(words, (line) => {
    if (line.indexOf('<br>') > -1) {
      const lines = line.split('<br>')

      each(lines, (line, index) => {
        innerHTML += index > 0 ? '<br>' + parseLine(line) : parseLine(line)
      })
    } else {
      innerHTML += parseLine(line)
    }
  })

  element.innerHTML = innerHTML

  const spans = element.querySelectorAll('span')

  if (append) {
    each(spans, (span) => {
      const isSingleLetter = span.textContent.length === 1
      const isNotEmpty = span.innerHTML.trim() !== ''
      const isNotAndCharacter = span.textContent !== '&'
      const isNotDashCharacter = span.textContent !== '-'

      if (
        isSingleLetter &&
        isNotEmpty &&
        isNotAndCharacter &&
        isNotDashCharacter
      ) {
        span.innerHTML = `${span.textContent}&nbsp;`
      }
    })
  }

  return spans
}

export const splitLines = (spans) => {
  const parent = spans[0].parentElement
  const lines = calculate(spans)

  parent.innerHTML = ''

  each(lines, (line, index) => {
    const isLast = index >= lines.length - 1
    const element = document.createElement('span')
    const br = document.createElement('br')

    each(line, (span, number) => {
      element.appendChild(span)
    })

    parent.appendChild(element)
    if (!isLast) parent.appendChild(br)
  })

  return parent.querySelectorAll('span > s')
}
