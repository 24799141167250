import React, { useEffect } from 'react'
import './NavigationStyle.scss'

import circle from 'assets/images/home/navigation-circle.png'
import line from 'assets/images/home/navigation-line.png'

const Navigation = () => {
  const fields = window.fields_page

  const changedTab = () => {
    const page = document.querySelector('main.page')
    const navigation = page.querySelector('.navigation')
    const line = navigation.querySelector('.navigation-line')
    const items = navigation.querySelector('.navigation-content')
    const allItems = items.querySelectorAll('.item')
    const index = window.tab_index
    const new_index = index - 1

    if (allItems[new_index].classList.contains('active')) return

    for (let i = 0; i < allItems.length; i++) {
      const item = allItems[i]

      if (item.classList.contains('active')) {
        item.classList.remove('active')
      }
    }

    line.setAttribute('data-position', index)
    allItems[new_index].classList.add('active')
  }

  const setTab = (index) => {
    const page = document.querySelector('main.page')

    window.tab_index = index
    page.dispatchEvent(new Event('changedTab'))
  }

  useEffect(() => {
    const page = document.querySelector('main.page')

    page.addEventListener('changedTab', changedTab, false)
  }, [])

  return (
    <div className="navigation">
      <div className="navigation-circle">
        <img src={circle} alt="Imagem - Alpa" />
      </div>

      <div className="navigation-line" data-position="0">
        <img src={line} alt="Imagem - Alpa" />
      </div>

      <div className="navigation-content">
        {fields['main-content'].map((item, index) => (
          <div
            className="item"
            data-index={index + 1}
            onClick={() => setTab(index + 1)}
            key={`home-navigation-${index + 1}`}
          >
            <div className="item-title">
              <div className="item-title--transform">
                <span
                  dangerouslySetInnerHTML={{ __html: item['title-navigation'] }}
                ></span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Navigation
