import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './TabsStyle.scss'

import { split, splitLines } from 'utils/text'

import { ReactComponent as ArrowRight } from 'assets/vectors/general/button-arrow-right.svg'
import { ReactComponent as Circle } from 'assets/vectors/general/button-circle.svg'

const Tabs = () => {
  const fields = window.fields_page

  const changedTab = () => {
    const page = document.querySelector('main.page')
    const tabs = page.querySelector('.tabs')
    const allTitles = tabs.querySelectorAll('.title')
    const allDescriptions = tabs.querySelectorAll('.description')
    const allCta = tabs.querySelectorAll('.cta')
    const index = window.tab_index
    const new_index = index - 1

    if (allTitles[new_index].classList.contains('active')) return

    tabs.setAttribute('data-background', index)

    for (let i = 0; i < allTitles.length; i++) {
      const title = allTitles[i]
      const description = allDescriptions[i]
      const cta = allCta[i]

      if (title.classList.contains('active')) {
        title.classList.remove('active')
        description.classList.remove('active')
        cta.classList.remove('active')
      }
    }

    allTitles[new_index].classList.add('active')
    allDescriptions[new_index].classList.add('active')
    allCta[new_index].classList.add('active')
  }

  useEffect(() => {
    const page = document.querySelector('main.page')
    const tabs = page.querySelector('.tabs')
    let text = 'linear-gradient(to bottom right'

    for (let i = 0; i < fields['main-content'].length; i++) {
      const item = fields['main-content'][i]
      const color1 = item['color-1']
      const color2 = item['color-2']
      const x = i + 1
      const item_text = `, ${color1} ${i * 12.5}%, ${color2} ${x * 12.5}%`

      text = text + item_text
    }

    text = text + ')'
    tabs.style.backgroundImage = text

    page.addEventListener('changedTab', changedTab, false)
  }, [fields])

  useEffect(() => {
    const page = document.querySelector('#page-home')
    const tabs = page.querySelector('.tabs')
    const allTitles = tabs.querySelectorAll('.title')

    for (let i = 0; i < allTitles.length; i++) {
      const title = allTitles[i]

      split(title)
      splitLines(title.querySelectorAll('s'))
    }
  }, [])

  const SetupTab = ({ item, index }) => {
    const pages = {
      74: 'plug-and-play',
      113: 'knowledge-enhancement',
      117: 'omnichannel-ecosystem',
      122: 'smart-factory',
      125: 'sustainability-boost',
      128: 'meaningful-communities',
      131: 'tech-for-new-products',
      134: 'lifestyle-as-a-service',
    }

    return (
      <>
        <div
          className="title"
          dangerouslySetInnerHTML={{ __html: item.title }}
          data-index={index + 1}
        ></div>

        <div
          className="description"
          data-index={index + 1}
          dangerouslySetInnerHTML={{ __html: item.description }}
        ></div>

        <div className="cta" data-index={index + 1}>
          <Link
            to={`/home/${pages[item['link-button']]}?preloader=false`}
            className="cta-button"
            title={fields['text-button']}
            aria-label={fields['text-button']}
          >
            {fields['text-button']}
          </Link>

          <div className="cta-title">{fields['text-button']}</div>

          <div className="cta-circle">
            <div className="cta-circle--element">
              <Circle />
            </div>

            <div className="cta-circle--arrow">
              <ArrowRight />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="tabs" data-background="1">
      {fields['main-content'].map((item, index) => (
        <SetupTab item={item} index={index} key={`home-tab-${index + 1}`} />
      ))}
    </div>
  )
}

export default Tabs
